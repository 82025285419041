import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bc723d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pan-info" }
const _hoisted_2 = { class: "pan-info-roles-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({zIndex: _ctx.zIndex, height: _ctx.height, width: _ctx.width}),
    class: "pan-item"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", {
      style: _normalizeStyle({backgroundImage: `url(${_ctx.image})`}),
      class: "pan-thumb"
    }, null, 4)
  ], 4))
}