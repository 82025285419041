
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    className: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: 'vue3-compisition-admin'
    }
  }
});
