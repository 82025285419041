import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36bd2642"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-letters"]
const _hoisted_2 = ["data-letters"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass([_ctx.className, "link--mallki"]),
    href: "#"
  }, [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
    _createElementVNode("span", { "data-letters": _ctx.text }, null, 8, _hoisted_1),
    _createElementVNode("span", { "data-letters": _ctx.text }, null, 8, _hoisted_2)
  ], 2))
}